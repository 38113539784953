

import Button from '@/components/Button.vue'

export default ({
    name: 'Doctors',
    components:{
        Button
    }
})
